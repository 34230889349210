
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  position: relative;
  background-color: #ffffff;
  height: 100vh;
  background-color: #ffffff;
}

.container-kaped {
  position: relative;
  transform: translate(-2%,0%);
  width: 520px;
  height: 120px;
  border-radius: 20px;
  background: linear-gradient(to right bottom, #323941, #061018);
  box-shadow: 0px 56px 72px -30px rgba(50, 55, 63, 0.66);
  padding: 30px;
  cursor: pointer;
  overflow: hidden;
}

  .card-name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.473);
  }

  .card-balance {
    color: white;
    font-size: 22px;
  }

  .logos {
    width: 50px;
    position: absolute;
    right: 30px;
    top: 45px;
  }

  .sim {
    width: 40px;
    position: absolute;
    bottom: -40px;
    opacity: 0;
  }

  .card-number {
    right: -200px;
    position: absolute;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.473);
    bottom: 56px;
  }

  .due-date {
    right: -100px;
    position: absolute;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.473);
    bottom: 30px;
  }

  .card-logos{
    position: absolute;
    top: -40px;
    right: -550px;
    height: 200px;
    transform: rotate(-12deg);
  }
  
  
